
// Development
// export const server_name = 'http://localhost:5001/';
// export const golang_name = 'http://localhost:5003';
// export const served_on = 'http://localhost:3000/';


 
//Production
export const server_name = 'http://35.238.133.32:5001/';
export const golang_name = 'http://35.238.133.32:5003';
export const served_on = 'http://35.238.133.32/';
